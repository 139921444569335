.vh-50 {
    height: 50vh !important;
}

.vh-75 {
    height: 75vh !important;
}

.vh-80 {
    height: 80vh !important;
}

.border-none {
    border: none !important;
}

.outline-none {
    outline: none !important;
}

.checkbox-dropdown {
    position: relative;
}

.checkbox-dropdown__toggle {
    cursor: pointer;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.checkbox-dropdown__options {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 5px;
    width: 200px;
}

.checkbox-dropdown__option {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.checkbox-dropdown__option input {
    margin-right: 5px;
}

.checkbox-dropdown__empty {
    margin-top: 5px;
    color: #999;
    font-style: italic;
}

.checkbox-dropdown__selected-options {
    margin-top: 5px;
    font-weight: bold;
}

.cursor-pointer {
    cursor: pointer;
}

.sig-canvas {
    width: 100%;
    height: 100%;
}

/* Hide arrows in number inputs for all browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}


.ip-discharge-billing-table-styles {
    width: 100%;
    border: 1px solid #556ee6;
    border-collapse: collapse;

    th,
    td {
        border: 1px solid #556ee6;
    }
}

.modal-body,
.scrollbar-thin {
    overflow: "scroll";
    scrollbar-width: thin;
}