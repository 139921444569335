.chip {
  display: inline-block;
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  border-radius: 22px;
  background-color: #dfdfdf;
  margin: 3px;
}

.closebtn {
  padding-left: 6px;
  color: #484848;
  float: right;
  font-size: 20px;
  cursor: pointer;
}

.closebtn:hover {
  color: #f00;
}